@import "../../../_globals";

.card-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 3px;
  width: calc(33.333vw - 0.75rem);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 0.25rem;
}
.card-header {
  margin-top: 2.5%;
  width: 95%;
  object-fit: cover;
}
.card-body {
  width: 100%;
  min-height: 20vh;
  text-align: center;
}
.card-header-img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: 1px solid lightgray;
}
.card-body-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0.2rem 0.5rem;

  .card-body-title-badge {
    border: solid 1px $green;
    white-space: nowrap;
    display: block;
    font-size: 0.7rem;
    padding: 0.2rem;
    border-radius: 6px;
    font-weight: bolder;
  }
}
.card-body-title {
  margin: 0.3rem 0;
  font-size: 1rem;
}

.card-body-description {
  font-size: 0.8rem;
  text-align: left;
  margin: 0 5%;
  font-size: 0.8rem;
}
.card-body-grid {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0 5%;
}
.event-status-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  h5 {
    text-align: center;
    margin: 0;
  }
  div {
    text-align: left;
    font-size: 0.8rem;
    padding-left: 0.25rem;
  }
}
.card-footer {
  text-align: center;
  width: 100%;
  margin: 0.5rem 0;
  margin-top: 0;
}

.card-footer-h4 {
  margin: 0.5rem auto;
}

.card-btn-group {
  padding: 0.25rem;
  button {
    &:first-child {
      margin-right: 0.2rem;
    }
    &:last-child {
      margin-left: 0.2rem;
    }
  }
}

@media screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .card-container {
    margin: 0.5rem;
    width: calc(50vw - 1rem);
  }
}
@media screen and (max-device-width: 500px) and (-webkit-min-device-pixel-ratio: 1) {
  .card-container {
    margin-top: 0.2rem;
    width: calc(100vw - 0.25rem);
  }
}

@media screen and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 1) {
  .card-body-header {
    text-align: center;
    // word-wrap: break-word;
    // white-space: normal;
  }
  .card-body-title {
    white-space: break-spaces;
  }
}

.lds-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid gray;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
