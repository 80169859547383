@import "../../../../globals";

// .ok-svg {
//   width: 60%;
//   height: 60%;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 1000;
// }

.icon-expand {
  &:hover::before {
    box-shadow: inset 0 0 0 1px white;
  }
}

.ok-icon {
  background-color: none;
  border: none;
  color: none;
}

.ok-icon {
  &::after {
    box-shadow: inset 0 0 0 1px $blue;
  }
  &::before {
    background: $blue;
    box-shadow: inset 0 0 0 $icon-size white;
  }
}
