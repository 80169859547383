.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  box-sizing: border-box;
}

.modal.modal-fade {
  animation: fade-in 1s 1 linear;
  animation-fill-mode: forwards;
  opacity: 0;
}

.modal > .modal-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal > .modal-close {
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  cursor: pointer;
  font-size: 1.25em;
  background: rgba(255, 255, 255, 0.749);
  border: 1px solid #c3c0c0;
  border-radius: 50%;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.3rem;
  margin: 0;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
}

.modal > .modal-close:hover {
  background-color: rgba(211, 211, 211, 0.4);
}

.modal > .modal-body {
  padding: 1rem;
  z-index: 2;
  position: relative;
  background-color: rgba(0, 0, 0, 0.03);
  overflow-y: auto;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

@keyframes fade-in {
  0% {
    animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-device-width: 425px) and (-webkit-min-device-pixel-ratio: 1) {
  .modal > .modal-body {
    padding: 0;
  }
}
