.home-main-events-header {
  text-align: center;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid gray;
  padding-bottom: 0.3rem;
  width: 30%;
}

.home-badge {
  padding: 0 1rem;
  img {
    width: 8rem;
    height: 8rem;
    margin-top: -1rem;
  }

  text-align: center;

  p {
    font-weight: bold;
    margin: 0;
  }
  .home-badge-title {
    margin-top: -2rem;
    margin-bottom: 1rem;
  }
  .home-badge-subtitle {
    margin-bottom: 1.5rem;
  }
}
