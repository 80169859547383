@import "../../../../globals";

.processing-icon{
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 42px;
    height:42px;
    border: solid 1px $orange;
    background:white;
}
  
#spin{
    width:100%;
    height:100%;
    animation-name: ckw;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

  @keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}