@import "../../globals";

.event-details-container {
  overflow: auto;
  padding: 0.05rem;
}
.event-details-jumbotron {
  width: 50%;
  margin: 0 auto;
  .jumbotron-container {
    border-radius: 10px;
  }
}
.event-title {
  text-align: center;
}
.event-description {
  margin: 0 auto;
  width: 75%;
  font-weight: bold;
}
.event-misc-group-title {
  text-align: center;
}
.event-misc-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  .event-info,
  .contact-info {
    margin: 0 1rem;
  }
}

.event-info {
  text-align: left;
  border-right: 1px solid gray;
  padding-right: 1.5rem;
}
.misc-group-header {
  text-align: center;
}

.event-info-header {
  span {
    font-weight: bold;
  }
}

//Package
.event-chevron-down {
  padding: 0.5rem;
  border: 1px solid lightgray;
  border-radius: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 0.5rem;
}
.event-package {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  width: 100%;
}

.event-package-main {
  width: 25vw;
}
.event-package-details {
  width: 55%;
}
.event-package-main-misc {
  text-align: center;
  margin: 0 auto;
}

.event-package-secondary-misc {
  display: flex;
  flex-wrap: nowrap;
  width: 65vw;
}

.event-package-brand {
  text-align: center;
  img {
    width: 8rem;
  }
}

.event-package-list {
  list-style-type: circle;
  text-align: left;
}

.event-package-room-config {
  text-align: center;
}
.event-package-room-config-table {
  th {
    border-bottom: 1px solid gray;
  }
  td,
  th {
    padding: 0.2rem;
  }
  border-collapse: collapse;
  tr:nth-child(even) {
    background: #e8e8e8;
  }
}

.event-package-room-config-table-caveat {
  font-size: 0.9rem;
  font-weight: bold;
}

@media screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .event-details-container {
    padding: 0.5rem;
  }
  .event-package {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .event-package-main {
    text-align: center;
    width: 100%;
  }
  .event-package-header {
    margin: 0 auto;
  }
  .event-package-dates-fromto,
  .event-package-brand {
    margin: 0 auto;
    text-align: center;
    width: 50%;
  }
  .event-package-main-misc,
  .event-package-secondary-misc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 0;
  }
  .event-package-secondary-misc {
    align-items: flex-start;
  }
  .event-package-list {
    padding-left: 1rem;
  }
}

.event-package-room-config-table {
  margin: 0 auto;
  text-align: center;
}

.event-details-header{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.25rem;

}

.event-partner-badge{
  border: solid 1px gray;
  max-width: 25%;
  font-size: 0.7rem;
  padding: 0.25rem;
  border-radius: 6px;
  margin-left:0.5rem;
  font-weight:bold;

}

@media screen and (max-device-width: 500px) and (-webkit-min-device-pixel-ratio: 1) {
  .event-details-jumbotron {
    width: 100%;
  }
  .event-title,
  .event-info-header,
  .event-package-dates-fromto,
  .event-package-list,
  .event-package-room-config-table {
    font-size: 0.75rem;
  }
  .event-description {
    width: 100%;
    font-size: 0.75rem;
  }
  .event-misc-group {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    .event-info {
      border-right: none;
      padding: 0;
      margin: 0;
    }
  }

  .event-package-main-misc {
    margin: 0;
  }
  .event-package-secondary-misc {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .event-package-details {
    width: 100%;
  }

  .event-package-room-config,
  .event-package-room-config-table {
    margin: 0 auto;
    text-align: center;
  }
}
