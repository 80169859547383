@import "../../../globals";

.contact-form-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}
.contact-form-header {
  text-align: center;
}

.contact-form-opening-message {
  margin: 0 auto;
}

.contact-form-header,
.contact-form-opening-message {
  margin-bottom: 0.5rem;
}

.contact-form-opening-message {
  font-size: 0.9rem;
  margin: 0 auto;
  width: 80%;
}
.contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  label {
    font-weight: bold;
  }
  .contact-form-error-message {
  }
  button {
    margin-top: 0.25rem;
  }
}

.contact-form-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .contact-form-item-header {
    text-align: center;
    width: 100%;
    margin: 1rem auto;
  }
  label,
  input {
    margin-right: 0.5rem;
  }
  select {
    max-width: 90%;
  }
  .contact-form-item {
  }
}

.preferred_contact_method {
  margin: 1rem 0;
}

.contact-form-error-message {
  height: 1.5rem;
  padding: 0.2rem 0.3rem;
  font-weight: bold;
  color: $orange;
}

.contact-form-inquiry {
  width: 80%;

  label {
    margin-top: 0.25rem;
    width: 100%;
    text-align: center;
  }
  textarea {
    width: 100%;
  }
}
.submit-result-banner {
  margin-top: 3rem;
  padding: 0.5rem;
}
.submit-result-banner-email {
  font-weight: bold;
  color: $orange;
}

@media screen and (max-device-width: 500px) and (-webkit-min-device-pixel-ratio: 1) {
  .contact-form-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-form-opening-message {
    font-size: 0.9rem;
    margin: 0 auto;
  }
}

