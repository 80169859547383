@import "../../../../globals";

// .whatsapp-svg {
//   width: 60%;
//   height: 60%;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 1000;
// }

.icon-expand {
  &:hover {
    color: white;
    transition: color 0.3s;
  }
  &:hover::before {
    box-shadow: inset 0 0 0 1px white;
  }
}

.whatsapp-icon {
  background-color: none;
  border: none;
  color: none;
  &::after {
    box-shadow: inset 0 0 0 1px $green;
  }
  &::before {
    background: $green;
    box-shadow: inset 0 0 0 $icon-size white;
  }
}
