* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
body {
  margin: 0;
  padding: 0;
  color: black;
  background-color: rgba(0, 0, 0, 0.03);
}

//Buttons
.btn-group {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .btn {
    margin: 0.1rem 0.2rem;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.4rem;
  margin: 0;
  font-size: 0.85rem;
  font-weight: bold;
  background-color: #f5f5f5;
  border-radius: 3px;

  //ADD BTN SHADDOWS

  //LOOK FOR COOL BTN Designs...

  img {
    margin-right: 0.2rem;
    width: 1.2rem;
  }
  p {
    margin: 0;
  }
}

.btn-neutral {
}

.btn-info {
  background-color: rgba(173, 216, 230, 0.4);
}

.btn-success {
  background-color: rgba(144, 238, 144, 0.4);
}

.btn-warn {
}

.btn-failure {
}

.btn-danger {
}

//buttons
$icon-size: 42px;
$border-radius: 0.5; //15% = 0.15, 50% = 0.50 etc.

$background: #2d2c3e;
$background-b: #2d2c3e;

// Icon Colors
$green: #16a085;
$red: #c82647;
$green-l: #8cc63f;
$orange: #eb9532;
$purple: #7e3661;
$blue: #6591d3;

// Text
$white: #ffffff;

.btn-container {
  display: table;
  height: 95vh;
  width: 90%;
  padding-bottom: 5vh;
  margin: auto auto;
  @media (max-width: 900px) {
  }
  background: $background;
  z-index: -1;
}

.btn-content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.btn-element {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0.25rem;
  font-size: 0.8rem;
}
.open-event-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.open-event-btn {
  .btn-label {
    font-weight: bold;
  }
}

.icon-container {
  margin-top: 30px;
  width: 100%;
  height: $icon-size;
}

.btn-svg {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.icon {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: $icon-size;
  height: $icon-size;
  margin-left: $icon-size/5;
  margin-right: $icon-size/5;
  border-radius: $icon-size * $border-radius;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.25s ease;
    border-radius: $icon-size * $border-radius;
  }
  i {
    position: relative;
    color: $white;
    font-size: $icon-size/2;
    margin-top: $icon-size/4;
    transition: all 0.25s ease;
  }
}

.icon-fill {
  &::before {
    transition-duration: 0.5s;
    box-shadow: inset 0 0 0 1px $green;
  }
  &:hover::before {
    box-shadow: inset 0 0 0 $icon-size $green;
  }
}

.icon-enter {
  &::after {
    box-shadow: inset 0 0 0 1px $orange;
  }
  &::before {
    border-radius: 0;
    margin-left: -100%;
    box-shadow: inset 0 0 0 $icon-size $orange;
  }
  &:hover::before {
    margin-left: 0;
  }
}

.icon-collapse {
  &::before {
    border-radius: 0;
  }
  &:hover::before {
    box-shadow: inset 0 $icon-size/2 0 0 $green-l,
      inset 0 $icon-size/-2 0 0 $green-l;
  }
  &::after {
    box-shadow: inset 0 0 0 1px $green-l;
  }
}

.icon-rotate {
  box-shadow: inset 0 0 0 1px $purple;
  &::after,
  &::before {
    border: 0px solid transparent;
  }
  &:hover::before {
    transition: border-top-width 0.3s ease, border-top-color 0.3s ease;
    border-width: $icon-size;
    border-top-color: $purple;
  }
  &:hover::after {
    transition: border-left-width 0.3s ease, border-left-color 0.3s ease;
    border-width: $icon-size;
    border-left-color: $purple;
  }
  &:hover {
    transition: background 0.001s ease 0.3s;
    background: $purple;
  }
  i {
    z-index: 1;
  }
}

.event-bottom-logo {
  margin: 0 auto;
  text-align: center;
  margin-top: 0.5rem;
  max-height: calc(100vh - 2rem);
  width: 80vw;
}
