@import "../../../../globals";

.icon-expand {
  &:hover {
    color: white;
    transition: color 0.3s;
  }
  &:hover::before {
    box-shadow: inset 0 0 0 1px white;
  }
}

.event-icon {
  &::after {
    box-shadow: inset 0 0 0 1px $orange;
  }
  &::before {
    background: $orange;
    box-shadow: inset 0 0 0 $icon-size white;
  }
}

.event-icon {
  background-color: none;
  border: none;
  color: none;
  &::after {
    box-shadow: inset 0 0 0 1px $orange;
  }
  &::before {
    background: $orange;
    box-shadow: inset 0 0 0 $icon-size white;
  }
}
