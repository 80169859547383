.nav {
  z-index: 9999;
  width: 100%;
  position: relative;
  background-color: white;
  box-shadow: rgba(33, 35, 38, 0.33) 0px 10px 10px -10px;
  padding: 0 0.5rem;
  padding-top: 0.25rem;
}

.nav > .nav-header {
  display: inline;
  margin-left: 0.5rem;
}

.nav-header-logo {
  margin: 0.25rem;
}

.nav > .nav-header > .nav-title {
  display: inline-block;
  font-size: 22px;
  color: black;
  padding: 10px 10px 10px 10px;
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  display: none;
  float: right;
  font-size: 18px;
}

.nav > .nav-links > a {
  display: inline-block;
  padding: 0.5rem;
  text-decoration: none;
  color: black;
}

.nav > .nav-links > a:hover {
  box-shadow: rgba(33, 35, 38, 0.3) 0px 10px 10px -10px;
}

.nav > #nav-check {
  display: none;
}

@media (max-width: 600px) {
  .nav > .nav-btn {
    // display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .nav > .nav-btn > label:hover,
  .nav #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(255, 255, 255, 0.3);
    color: #fff;
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid rgb(175, 174, 174);
  }
  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    height: 0px;
    transition: all 0.25s ease-in;
    overflow-y: hidden;
    top: 46px;
    left: 0px;
  }
  .nav > .nav-links > a {
    display: block;
    color: white;
    font-weight: bold;
    width: 100%;
  }
  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }
  .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 45px);
    overflow: hidden;
  }
}

.nav > .nav-btn {
  display: none;
}
