.jumbotron-container {
  margin: 0 auto;
  margin-bottom: 0.5rem;
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 4px;
    border-radius: 2px;
  }
}

.jumbotron-title-background {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0 0 4px 4px;
  color: white;
  padding: 0 0.5rem;
  top: 0;
  left: 30%;
  width: 45%;
}
